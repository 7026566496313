export type SiteConfig = typeof siteConfig
export const siteConfig = {
    name: "My Femboy Roommate",
    slogan: "slogan",
    icon: "/favicon.ico",
    appleIcon: "/apple-touch-icon.png",
    gameIframe: "https://html-classic.itch.zone/html/11277493-1110738/Roommate-Demo-web/index.html",
    domain: "myfemboyroommate.com",
    gaId: "G-6QVE9YLFVX",
    navbarItems: [
        {
            title: "title",
             href: "/",
        }
    ],
}
